import { useEffect, useState } from "react";

import { motion } from "framer-motion";

import { ScrollRestoration } from "react-router-dom";

export default function AnimatedAppear(props) {
    const [canScroll, setCanScroll] = useState(false);
  
    useEffect(() => {
      if (canScroll === false) {
        document.querySelector("body").classList.add("no-scroll");
      } else {
        document.querySelector("body").classList.remove("no-scroll");
      }
    }, [canScroll]);
  
    return (
        <motion.div
            onAnimationComplete={() => setCanScroll(true)}
            initial={{ y: 150, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: .2, duration: .5 }}
            //exit={{ y: -200, opacity: 0 }}
        >
            <ScrollRestoration/>
            {props.children}
        </motion.div>
    )
}