import { useState, useEffect, useRef, useLayoutEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import i18n from './i18n'

import Lenis from "@studio-freight/lenis"

import Menu from "./components/Menu"
import Footer from "./components/Footer";
import CustomCursor from "./components/CustomCursor";


function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function Frame(props) {
  const ref = useRef(null)
  const location = useLocation();
  const navigate = useNavigate();

  const [width, height] = useWindowSize();
  const mobile = width <= 1000

  useEffect(() => {
    const lenis = new Lenis({ smoothWheel: true, smoothTouch: false, touchInertiaMultiplier: 20 })
    
    function raf(time) {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }

    /*lenis.on('scroll', (e) => {
      console.log(e)
    })*/
    
    requestAnimationFrame(raf)
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const lang = params.get('lang');
    if (lang) {
      i18n.changeLanguage(lang);
      params.delete('lang');
      navigate({ search: params.toString() }, { replace: true });
    }
  }, [location, navigate]);

  return (
    <div className="App" ref={ref}>
      {!mobile && <CustomCursor appTargetRef={ref}/>}
      <Menu/>
      <Outlet />
      <Footer />
    </div>
  )
}
