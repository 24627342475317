import { useRef, useState, useEffect, useLayoutEffect } from "react";

import { Trans } from "react-i18next";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import ScrollDown from "../components/ScrollDown";
import AnimatedAppear from "../components/AnimatedAppear";

import IconClose from "../assets/close2";

import "../styles/clients.scss";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function LazyBackground(p) {
  const [width, height] = useWindowSize();
  const [src, setSrc] = useState(null);

  useEffect(() => {
    if (width > 0) {
      const mobile = width < 1200;
      let localSrc = mobile && p.mobileSrc ? p.mobileSrc : p.src;

      //console.log(width)

      const imageLoader = new Image();
      imageLoader.src = localSrc;
      imageLoader.onload = () => {
        //console.log("loaded")
        setSrc(localSrc);
      };
    }
  }, [width]);

  return (
    <div
      className={p.className}
      style={
        src ? { backgroundImage: `url(/${src})` } : { backgroundColor: p.color }
      }
    >
      {p.children}
    </div>
  );
}

export default function Clients() {
  const [mobileHover, setMobileHover] = useState(null);
  const heroRef = useRef(null);

  function toggleMobileHover(tar) {
    if (tar === mobileHover) {
      return setMobileHover(null);
    } else {
      return setMobileHover(tar);
    }
  }

  return (
    <AnimatedAppear>
      <div className="clients">
        <ScrollDown hideOnMobile appTargetRef={heroRef} />

        <div className="row" ref={heroRef}>
          <div className="subCol">
            <h1>
              <Trans i18nKey="ourClients.title" components={{ b: <b /> }} />
            </h1>
            <LazyBackground
              className={`tile ${mobileHover === 0 ? "hoveredMobile" : ""}`}
              color={"#D9BFA4"}
              src={"clientsHima.jpg"}
              mobileSrc={"clientsHimaMobile.png"}
            >
              <div className="title">
                Himalight <small>2023</small>
              </div>
              <div className="hover">
                <div>
                  <small>2023</small>
                  <b>
                    <Trans i18nKey="ourClients.dev" />
                  </b>
                </div>
              </div>
              <div className="mobilePlus" onClick={() => toggleMobileHover(0)}>
                <IconClose />
              </div>
            </LazyBackground>
          </div>

          <div className="subCol">
            <div
              className={`tile video ${mobileHover === 1 ? "hoveredMobile" : ""}`}
            >
              <div className="title">
                No Matter <small>2023</small>
              </div>

              <div className="hover">
                <div>
                  <small>2023</small>
                  {/* Commenting the Link component */}
                  {/* <Link to="https://website.org"> */}
                  <b>
                    <Trans i18nKey="ourClients.website" />
                  </b>
                  {/* </Link> */}
                </div>
                <div>
                  {/* Commenting the Link component */}
                  {/* <Link to="https://website.org"> */}
                  <b>
                    <Trans i18nKey="ourClients.mobileApp" />
                  </b>
                  {/* </Link> */}
                </div>
                <div>
                  {/* Commenting the Link component */}
                  {/* <Link to="https://website.org"> */}
                  <b>
                    <Trans i18nKey="ourClients.insta" />
                  </b>
                  {/* </Link> */}
                </div>
              </div>

              <video autoPlay loop muted preload="auto" playsInline>
                <source src={`https://assets.vkode.fr/dstudio/dstudio_noMatter.mp4`} type="video/mp4" />
              </video>

              <div className="mobilePlus" onClick={() => toggleMobileHover(1)}>
                <IconClose />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <LazyBackground
            className={`tile ${mobileHover === 2 ? "hoveredMobile" : ""}`}
            color={"#E9502E"}
            src={"clientsBiyon.jpg"}
            mobileSrc={"clientsBiyonMobile.png"}
          >
            <div className="title">
              Biyon <small>2023</small>
            </div>

            <div className="hover">
              <div>
                <small>2023</small>
                {/* Commenting the Link component */}
                {/* <Link to="https://website.org"> */}
                <b>
                  <Trans i18nKey="ourClients.website" />
                </b>
                {/* </Link> */}
              </div>
              <div>
                <small>
                  <Trans i18nKey="ourClients.dev" />
                </small>
                <b>
                  <Trans i18nKey="ourClients.app" />
                </b>
              </div>
            </div>

            <div className="mobilePlus" onClick={() => toggleMobileHover(2)}>
              <IconClose />
            </div>
          </LazyBackground>
        </div>

        {/* ... (Previous code) */}

        <div className="row">
          <LazyBackground
            className={`tile ${mobileHover === 3 ? "hoveredMobile" : ""}`}
            color={"#8250C1"}
            src={"clientsSegunda.jpg"}
            mobileSrc={"clientsSegundaMobile.png"}
          >
            <div className="title">
              Segunda Vida <small>2023</small>
            </div>

            <div className="hover">
              <div>
                <small>2023</small>
                <b>
                  <Trans i18nKey="ourClients.dev" />
                </b>
              </div>
            </div>

            <div className="mobilePlus" onClick={() => toggleMobileHover(3)}>
              <IconClose />
            </div>
          </LazyBackground>

          <LazyBackground
            className={`tile larger ${mobileHover === 4 ? "hoveredMobile" : ""}`}
            color={"#82D0DA"}
            src={"clientsSbc.jpg"}
            mobileSrc={"clientsSbcMobile.png"}
          >
            <div className="title">
              SBC <small>2023</small>
            </div>

            <div className="hover">
              <div>
                <small>2023</small>
                {/* Commenting the Link component */}
                {/* <Link to="https://website.org"> */}
                <b>
                  <Trans i18nKey="ourClients.website" />
                </b>
                {/* </Link> */}
              </div>
              <div>
                <small>
                  <Trans i18nKey="ourClients.dev" />
                </small>
                <b>Apps & Webapp</b>
              </div>
            </div>

            <div className="mobilePlus" onClick={() => toggleMobileHover(4)}>
              <IconClose />
            </div>
          </LazyBackground>
        </div>

        <div className="row">
          <LazyBackground
            className={`tile ${mobileHover === 5 ? "hoveredMobile" : ""}`}
            color={"#FC3C44"}
            src={"clientsChillz.png"}
            mobileSrc={"clientsChillzMobile.png"}
          >
            <div className="title">
              Chillz <small>2023</small>
            </div>

            <div className="hover">
              <div>
                <small>2023</small>
                {/* Commenting the Link component */}
                {/* <Link to="https://website.org"> */}
                <b>
                  <Trans i18nKey="ourClients.website" />
                </b>
                {/* </Link> */}
              </div>
              <div>
                <small>
                  <Trans i18nKey="ourClients.dev" />
                </small>
                <b>
                  <Trans i18nKey="ourClients.app" />
                </b>
              </div>
            </div>

            <div className="mobilePlus" onClick={() => toggleMobileHover(5)}>
              <IconClose />
            </div>
          </LazyBackground>
        </div>

        <div className="row">
          <LazyBackground
            className={`tile larger ${mobileHover === 6 ? "hoveredMobile" : ""}`}
            color={"#8C3ECA"}
            src={"clientsVS.png"}
            mobileSrc={"clientsVSMobile.png"}
          >
            <div className="title">
              Virtual Soul <small>2023</small>
            </div>

            <div className="hover">
              <div>
                <small>2023</small>
                {/* Commenting the Link component */}
                {/* <Link to="https://website.org"> */}
                <b>
                  <Trans i18nKey="ourClients.website" />
                </b>
                {/* </Link> */}
              </div>
              <div>
                {/* Commenting the Link component */}
                {/* <Link to="https://website.org"> */}
                <b>
                  <Trans i18nKey="ourClients.mobileApp" />
                </b>
                {/* </Link> */}
              </div>
              <div>
                {/* Commenting the Link component */}
                {/* <Link to="https://website.org"> */}
                <b>
                  <Trans i18nKey="ourClients.insta" />
                </b>
                {/* </Link> */}
              </div>
            </div>

            <div className="mobilePlus" onClick={() => toggleMobileHover(6)}>
              <IconClose />
            </div>
          </LazyBackground>

          <LazyBackground
            className={`tile border ${mobileHover === 7 ? "hoveredMobile" : ""}`}
            color={"#8A8A8A"}
            src={"clientsHs.jpg"}
            mobileSrc={"clientsHsMobile.png"}
          >
            <div className="title">
              Hashing squad <small>2023</small>
            </div>

            <div className="hover">
              <div>
                <small>2023</small>
                {/* Commenting the Link component */}
                {/* <Link to="https://website.org"> */}
                <b>
                  <Trans i18nKey="ourClients.website" />
                </b>
                {/* </Link> */}
              </div>
              <div>
                {/* Commenting the Link component */}
                {/* <Link to="https://website.org"> */}
                <b>
                  <Trans i18nKey="ourClients.insta" />
                </b>
                {/* </Link> */}
              </div>
            </div>

            <div className="mobilePlus" onClick={() => toggleMobileHover(7)}>
              <IconClose />
            </div>
          </LazyBackground>
        </div>

        <div className="row">
          <LazyBackground
            className={`tile larger ${mobileHover === 8 ? "hoveredMobile" : ""}`}
            color={"#524CE8"}
            src={"clientsBypto.png"}
            mobileSrc={"clientsByptoMobile.png"}
          >
            <div className="title">Bypto</div>

            <div className="hover">
              <div>
                <small>2023</small>
                {/* Commenting the Link component */}
                {/* <Link to="https://website.org"> */}
                <b>
                  <Trans i18nKey="ourClients.website" />
                </b>
                {/* </Link> */}
              </div>
              <div>
                <small>
                  <Trans i18nKey="ourClients.dev" />
                </small>
                <b>
                  <Trans i18nKey="ourClients.app" />
                </b>
              </div>
            </div>

            <div className="mobilePlus" onClick={() => toggleMobileHover(8)}>
              <IconClose />
            </div>
          </LazyBackground>

          <LazyBackground
            className={`tile ${mobileHover === 9 ? "hoveredMobile" : ""}`}
            color={"#062441"}
            src={"clientsRei.png"}
            mobileSrc={"clientsReiMobile.png"}
          >
            <div className="title">
              Rei de Copes <small>2021</small>
            </div>

            <div className="hover">
              <div>
                <small>2021</small>
                {/* Commenting the Link component */}
                {/* <Link to="https://website.org"> */}
                <b>
                  <Trans i18nKey="ourClients.website" />
                </b>
                {/* </Link> */}
              </div>
              <div>
                {/* Commenting the Link component */}
                {/* <Link to="https://website.org"> */}
                <b>
                  <Trans i18nKey="ourClients.insta" />
                </b>
                {/* </Link> */}
              </div>
            </div>

            <div className="mobilePlus" onClick={() => toggleMobileHover(9)}>
              <IconClose />
            </div>
          </LazyBackground>
        </div>
      </div>

      <section className="getTouch">
        <motion.div
          className="borderFrame"
          initial={{
            scale: 1,
          }}
          whileInView={{
            scale: 0.9,
          }}
          viewport={{ once: false }}
          transition={{
            duration: 2,
            delay: 0.5,
          }}
        />

        <motion.div
          className="textFrame smaller"
          initial={{
            scale: 0.8,
            opacity: 0,
          }}
          whileInView={{
            scale: 1,
            opacity: 1,
          }}
          viewport={{ once: false }}
          transition={{
            duration: 1,
            delay: 0.1,
          }}
        >
          <h2>
            <Trans i18nKey="ourClients.cta" />
            <br />
            <Link to="/contact">Let’s start</Link>
          </h2>
        </motion.div>
      </section>
    </AnimatedAppear>
  );
}
