import { useTranslation } from "react-i18next";

import { useRouteError } from "react-router-dom";

export default function Legal() {
  const error = useRouteError()
  const { t, i18n } = useTranslation()

  return (
    <div id="legal">
        <h1>CGU</h1>
        <p>




        
        <h2>Mentions légales</h2>

        Statut juridique : SAS (société par actions simplifiée)<br/>

        Capital social : 1000€<br/>

        Numéro d’identification fiscale : 98316079700012<br/><br/>

        Directeur de la publication : Victor Cherki<br/>

        Adresse : 7 RUE LAURISTON, 75116 PARIS 16<br/>

        Adresse mail : contact@dstudio.company<br/><br/>

        Hébergeur du site :<br/>
        Hostinger<br/>
        International Ltd – 61 Lordou Vironos str. 6023 Larnaca, Chypre.<br/>
        Les données sont hébergées exclusivement en France métropolitaine.<br/><br/>

        D-Studio est une agence spécialisée dans la conception et le développement de sites web, le marketing digital et les stratégies de communication en ligne.<br/>

        <h2>Conditions Générales d'Utilisation</h2>

        En accédant et en utilisant ce site, vous acceptez les présentes conditions générales d'utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser ce site.<br/><br/>

        1. Utilisation du site : Vous vous engagez à utiliser ce site conformément aux lois en vigueur et de manière licite.<br/><br/>

        2. Responsabilités de l'utilisateur : L'utilisateur est seul responsable de l'utilisation qu'il fait du site. D-Studio décline toute responsabilité en cas d'utilisation non conforme aux présentes CGU.<br/><br/>

        3. Propriété intellectuelle : Les contenus présents sur ce site (textes, images, vidéos, etc.) sont la propriété exclusive de D-Studio ou de ses partenaires et sont protégés par les lois sur la propriété intellectuelle.<br/><br/>

        4. Protection des données personnelles : Conformément à la réglementation en vigueur, nous collectons et traitons vos données personnelles dans le respect de votre vie privée. Pour plus d'informations, veuillez consulter notre Politique de Confidentialité.<br/><br/>

        5. Liens externes : Ce site peut contenir des liens vers des sites externes sur lesquels D-Studio n'exerce aucun contrôle. Nous déclinons toute responsabilité quant au contenu de ces sites externes.<br/><br/>

        6. Clause de limitation de responsabilité : D-Studio ne saurait être tenue pour responsable des dommages directs ou indirects résultant de l'utilisation de ce site ou de l'impossibilité de l'utiliser.<br/><br/>

        7. Loi applicable et juridiction compétente : Les présentes CGU sont soumises au droit français. En cas de litige, les tribunaux de Paris seront seuls compétents.<br/><br/>

        8. Modification des CGU : D-Studio se réserve le droit de modifier à tout moment les présentes CGU. Les utilisateurs sont invités à les consulter régulièrement.<br/><br/>

        </p>
    </div>
  )
}