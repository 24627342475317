export default function menuBg() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="401.25" height="52.75" viewBox="0 0 401.25 52.75">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_47" data-name="Rectangle 47" width="401.25" height="52.75" fill="none"/>
          </clipPath>
        </defs>
        <g id="Groupe_311" data-name="Groupe 311" clipPath="url(#clip-path)">
          <path id="Tracé_7280" data-name="Tracé 7280" d="M400.875,26.405a25.832,25.832,0,0,1-46.94,14.89.35.35,0,0,1-.05-.08c-.05-.08-.11-.15-.16-.23h-.01c-.05-.08-.129-.232-.189-.312a11.486,11.486,0,0,0-9.281-4.588c-3.91,0-7.694,1.664-9.593,4.905l0-.005a22.009,22.009,0,0,1-19.28,11.39h-293a22,22,0,0,1-22-22v-8a22,22,0,0,1,22-22h293a21.939,21.939,0,0,1,18.03,9.4c.17.24.34.48.49.73a10.99,10.99,0,0,0,.71,1.19.139.139,0,0,1,.03.04,11.626,11.626,0,0,0,18.57.86.01.01,0,0,0,.01-.01c.43-.53.715-1.115,1.16-1.69.29-.38.59-.76.9-1.12a25.828,25.828,0,0,1,45.6,16.63" fill="#161617"/>
          <path id="Tracé_7281" data-name="Tracé 7281" d="M400.875,26.405a25.832,25.832,0,0,1-46.94,14.89.35.35,0,0,1-.05-.08c-.05-.08-.11-.15-.16-.23h-.01c-.05-.08-.129-.232-.189-.312a11.486,11.486,0,0,0-9.281-4.588c-3.91,0-7.694,1.664-9.593,4.905l0-.005a22.009,22.009,0,0,1-19.28,11.39h-293a22,22,0,0,1-22-22v-8a22,22,0,0,1,22-22h293a21.939,21.939,0,0,1,18.03,9.4c.17.24.34.48.49.73a10.99,10.99,0,0,0,.71,1.19.139.139,0,0,1,.03.04,11.626,11.626,0,0,0,18.57.86.01.01,0,0,0,.01-.01c.43-.53.715-1.115,1.16-1.69.29-.38.59-.76.9-1.12a25.828,25.828,0,0,1,45.6,16.63Z" fill="none" stroke="#353538" strokeWidth="0.75"/>
        </g>
      </svg>
)}
  

