import { useEffect, useRef } from 'react';
//import './TextSlider.css';

import {
    motion,
    useScroll,
    useSpring,
    useTransform,
    useMotionValue,
    useVelocity,
    useInView,
    //useInView,
    useAnimationFrame
} from "framer-motion";

import { wrap } from "@motionone/utils";


function ParallaxText({ children, baseVelocity = 10 }) {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
 // const scrollRef = useRef(null)
  const scrollVelocity = useVelocity(scrollY);
  const directionFactor = useRef(-1);
  const divRef = useRef(null)

  const isInView = useInView(divRef)

  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400
  });

  const velocityFactor = useTransform(smoothVelocity, [-1000, 0, 1000], [1, 0, 1], {
    clamp: false
  });

  /**
   * This is a magic wrapping for the length of the text - you
   * have to replace for wrapping that works for you or dynamically
   * calculate
   */
  const x = useTransform(baseX, (v) => `${wrap(-200, -145, v)}vw`)

  useAnimationFrame((t, delta) => {
    let moveBy = directionFactor.current * (baseVelocity * (delta / 1000));
    moveBy += directionFactor.current * (moveBy * (velocityFactor.get() * -1));

    baseX.set(baseX.get() + moveBy);
  });

  //console.log("isInView ->", isInView)
  /**
   * The number of times to repeat the child text should be dynamically calculated
   * based on the size of the text and viewport. Likewise, the x motion value is
   * currently wrapped between -20 and -45% - this 25% is derived from the fact
   * we have four children (100% / 4). This would also want deriving from the
   * dynamically generated number of children.
   */

  /*console.log(scrollRef)
  console.log(x)
  const isInView = useInView(scrollRef, { amount: .5 })

  console.log(isInView)*/

  return (
    <div className="scrolledText" /*ref={scrollRef}*/ ref={divRef}>
      <motion.div
        className="scroller"
        style={{ x: isInView ? x : 0 }}
        //viewport={{ once: false, root: scrollRef }}
        //transition={{ delay: 1, duration: 3}}
      >
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
      </motion.div>
    </div>
  );
}

export default function ScrolledText() {
  return (
    <section className="scrolledTextFrame">
      {/*<motion.div
        className="borderFrame"
        initial={{
          x: "90vw"
        }}
        whileInView={{
          x: 0
        }}
        viewport={{ once: false }}
        transition={{
          duration: 1.5,
          delay: .1
        }}
      >*/}
      <ParallaxText>Services·</ParallaxText>
    </section>
  );
}