import { useState, useEffect, useRef } from "react";

import { Trans } from "react-i18next";
import { motion } from "framer-motion";
import { throttle } from "lodash";

import Slider from "../components/Slider";

import Chevron from "../assets/chevron";

export default function WeAre(p) {
  const [controledSlider1Step, setControledSlider1Step] = useState(0);
  const [controledSlider1LeftPart, setControledSlider1LeftPart] = useState(1);
  const [controledSlider1RightPart, setControledSlider1RightPart] = useState(0);
  const [controledSlider1Reverse, setControledSlider1Reverse] = useState(false);

  const ref = useRef(null);
  const actualPos = useRef(0);

  useEffect(() => {
    const onWheel = throttle((e) => {
      let screenH = window.screen.height;
      let advance = e.pageY - screenH;
      let limit = ref.current.offsetHeight / 3;

      ///console.log("zzz")
      /*console.log("actualPos",actualPos.current)
            console.log("advance",advance)
            console.log("limit",limit)*/

      if (advance < limit) {
        if (actualPos.current === 1) {
          actualPos.current = 0;
          setControledSlider1RightPart(0);
          if (controledSlider1LeftPart === 0) setControledSlider1LeftPart(1);
        }
      } else if (advance < limit * 2) {
        if (actualPos.current === 0) {
          actualPos.current = 1;
          setControledSlider1RightPart(1);
          if (controledSlider1LeftPart === 0) setControledSlider1LeftPart(1);
        } else if (actualPos.current === 1) {
          setControledSlider1LeftPart(1);
          if (controledSlider1RightPart === 0) setControledSlider1LeftPart(1);
        }
      } else if (advance < limit * 3) {
        if (actualPos.current === 1) {
          actualPos.current = 1;
          setControledSlider1LeftPart(0);
          setControledSlider1Reverse(true);
          if (controledSlider1RightPart === 0) setControledSlider1RightPart(1);
        }
      }
    }, 200);

    ref.current.addEventListener("wheel", onWheel, {
      smooth: true /*passive: true*/,
    });

    return () => {
      ref.current?.removeEventListener("wheel", onWheel, {
        /*passive: true*/
      });
    };
  }, []);

  useEffect(() => {
    recalcValuesFirstSlider(
      controledSlider1LeftPart,
      controledSlider1RightPart,
    );
  }, [controledSlider1LeftPart, controledSlider1RightPart]);

  /* FIRST SLIDER HANDLERS */
  function recalcValuesFirstSlider(left, right, callback) {
    if (!controledSlider1Reverse) {
      if (left === 0 && right === 0) {
        setControledSlider1Reverse(true);
        setControledSlider1Step(2);

        if (right === 0) {
          setControledSlider1RightPart(1);
        }
      } else if (left === 1) {
        if (right === 1) {
          setControledSlider1Step(1);
        } else {
          setControledSlider1Step(0);
        }
      }
    } else {
      if (left === 0) {
        setControledSlider1Step(2);
      } else {
        if (right === 1) {
          setControledSlider1Step(1);
        } else {
          setControledSlider1Step(0);
          setControledSlider1Reverse(false);
        }
      }
    }
  }

  function handleFirstControlledSlider(e) {
    //console.log("controledSlider1Reverse -> ", controledSlider1Reverse)
    if (controledSlider1Reverse) {
      switch (controledSlider1Step) {
        case 2: {
          setControledSlider1Step(1);
          setControledSlider1LeftPart(1);
          break;
        }
        case 1: {
          setControledSlider1Step(0);
          setControledSlider1RightPart(0);
          setControledSlider1Reverse(false);
          break;
        }
      }
    } else {
      //console.log(controledSlider1Step)
      switch (controledSlider1Step) {
        case 0: {
          setControledSlider1Step(1);
          setControledSlider1RightPart(1);
          break;
        }
        case 1: {
          setControledSlider1Step(2);
          setControledSlider1LeftPart(0);
          setControledSlider1Reverse(true);
          break;
        }
      }
    }
  }
  function handleFirstControlledLeftSlider(newVal) {
    if (newVal !== controledSlider1LeftPart)
      setControledSlider1LeftPart(newVal);
  }
  function handleFirstControlledRightSlider(newVal) {
    if (newVal !== controledSlider1RightPart)
      setControledSlider1RightPart(newVal);
  }
  /* END OF FIRST SLIDER HANDLERS */

  /*console.log("controledSlider1Step -> ", controledSlider1Step);
    console.log("controledSlider1LeftPart -> ", controledSlider1LeftPart);
    console.log("controledSlider1RightPart -> ", controledSlider1RightPart);
    console.log("controledSlider1Reverse -> ", controledSlider1Reverse);*/

  return (
    <div ref={ref}>
      <section className="weAre">
        {!p.mobile && (
          <div
            className={`controlChevron clickable ${
              controledSlider1Reverse ? "reverse" : ""
            } ${
              controledSlider1LeftPart === 0 || controledSlider1RightPart === 1
                ? "grey"
                : ""
            }`}
            onClick={handleFirstControlledSlider}
          >
            <Chevron />
          </div>
        )}

        {p.mobile ? (
          <div className="slider">
            <motion.div
              initial={{ backgroundColor: "black", scale: 0.8 }}
              whileInView={{ backgroundColor: "white", scale: 1 }}
              viewport={{ once: false, margin: "10px" }}
              transition={{ duration: 0.2 }}
              className="slide main"
            >
              <span>
                <Trans
                  i18nKey="weAre.weAreDStudio"
                  components={{ br: <br /> }}
                />
              </span>
            </motion.div>
            <motion.div
              initial={{ backgroundColor: "black", scale: 0.8 }}
              whileInView={{ backgroundColor: "#82828b", scale: 1 }}
              viewport={{ once: false, margin: "10px" }}
              transition={{ duration: 0.2 }}
              className="slide large"
            >
              <Trans
                i18nKey="weAre.impact"
                components={{ b: <b/>, span: <span />, br: <br /> }}
              />
            </motion.div>
            <motion.div
              initial={{ backgroundColor: "black", scale: 0.8 }}
              whileInView={{ backgroundColor: "#82828b", scale: 1 }}
              viewport={{ once: false, margin: "10px" }}
              transition={{ duration: 0.2 }}
              className={`slide desc`}
            >
              <b>
                <Trans i18nKey="weAre.desc1" components={{ span: <span /> }} />
              </b>
              <b>
                <Trans i18nKey="weAre.desc2" components={{ span: <span /> }} />
              </b>
            </motion.div>
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="animSliderFrame"
          >
            <Slider
              active={1}
              forceActive={controledSlider1LeftPart}
              cbChanged={handleFirstControlledLeftSlider}
            >
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ delay: 0.5, duration: 0.5 }}
                className="slide large"
              >
                <Trans
                  i18nKey="weAre.impact"
                  components={{ b: <b/>, span: <span />, br: <br /> }}
                />
              </motion.div>

              <Slider
                active={0}
                cardMode
                forceActive={controledSlider1RightPart}
                cbChanged={handleFirstControlledRightSlider}
              >
                <div
                  className={`slide main ${
                    controledSlider1RightPart !== 0 ? "onBg" : ""
                  }`}
                >
                  <span>
                    <Trans
                      i18nKey="weAre.weAreDStudio"
                      components={{ br: <br /> }}
                    />
                  </span>
                </div>

                {/*<div className="slide snapped"/>*/}

                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.5, duration: 0.5 }}
                  className={`slide desc ${
                    controledSlider1RightPart === 1 ? "onTop" : ""
                  }`}
                >
                  <b>
                    <Trans
                      i18nKey="weAre.desc1"
                      components={{ span: <span /> }}
                    />
                  </b>
                  <b>
                    <Trans
                      i18nKey="weAre.desc2"
                      components={{ span: <span /> }}
                    />
                  </b>
                </motion.div>
              </Slider>
            </Slider>
          </motion.div>
        )}
      </section>

      {!p.mobile && (
        <section className="ghostForScroll weAre">
          <div className="slider">
            <div className="slide">____</div>
          </div>
          <div className="slider">
            <div className="slide">____</div>
          </div>
          <div className="slider">
            <div className="slide">____</div>
          </div>
          <div className="slider">
            <div className="slide">____</div>
          </div>
        </section>
      )}
    </div>
  );
}
