import { useEffect, useState, useLayoutEffect, useRef } from "react";

import { Link } from "react-router-dom";
import { motion } from "framer-motion"
import { throttle } from 'lodash';
import { Trans, useTranslation } from "react-i18next";

import { ReactComponent as Logo } from "../assets/logo.svg";
import logoSrc from "../assets/logo.png";

import MenuIcon from "../assets/menu";


function pannel(closeAnim,toggleCloseAnim,t) {

  return (
    <>
      <div className="animCover"/>

      <motion.div animate={closeAnim ? { y: -200, opacity: 0 } : {}}>
        <div className="menu">

          <nav>
            <Link to="process" onClick={() => toggleCloseAnim(true)}><Trans i18nKey="menu.howWeWork" /></Link>
            <Link to="clients" onClick={() => toggleCloseAnim(true)}>{t("menu.ourClients")}</Link>
            <Link to="contact" onClick={() => toggleCloseAnim(true)}>Contact</Link>
          </nav>

          <motion.div
            initial={{ opacity: 0, y: 300, scale: 0.5, x: 100 }}
            animate={{
              opacity: 1, y: 0, scale: 1, x: 0
            }}
            transition={{ duration: .8, type: "spring" }}
            className="top"
          >
            <Link to="/" onClick={() => toggleCloseAnim(true)}><Logo/></Link>
          </motion.div>

          <div className="bottom">
            <motion.div
              initial={{ opacity: 0, y: 50, scale: 0.5, x: 100 }}
              animate={{
                opacity: 1, y: 0, scale: 1, x: 0
              }}
              transition={{ duration: .8, type: "spring" }}
            >
              <Link to="contact" onClick={() => toggleCloseAnim(true)}>
                Let's <b>Start</b>
              </Link>
            </motion.div>
            <div className="line" />
          </div>
        </div>
      </motion.div>
    </>
  );
}


function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function Menu() {
  const { t, i18n } = useTranslation()

  const [menuOpen, toggleMenu] = useState(false);
  const [menuOpenThreeHundredMS, toggleMenuThreeHundredMS] = useState(false);
  const [closeAnim, toggleCloseAnim] = useState(false)

  const [isVisible, setIsVisible] = useState(true)
  const [prevScrollY, setPrevScrollY] = useState(0)
  const prevScrollYRef = useRef(0)
  const [isHovered, setIsHovered] = useState(false)

  const [width, height] = useWindowSize();
  const mobile = width <= 1000

  const isAtHome = window.location.pathname === "/"

  useEffect(() => {
    const handleScroll = throttle((e) => {
      //console.log(e)

      let prev = prevScrollYRef.current
      const currentScrollY = e.target.scrollingElement.scrollTop//e.view.scrollY;
      //console.log(currentScrollY)

      const scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const documentHeight = document.documentElement.scrollHeight;

      //let tolerance = 1
      const isScrollingDown = currentScrollY - prev > 1//e.deltaY > 1
      //console.log("isScrollingDown", currentScrollY - prev > 1)
      //console.log("isScrollingDown", currentScrollY - prev)
      const isScrollingUp = currentScrollY - prev < -1//e.deltaY < -1
      //console.log("isScrollingUp", currentScrollY - prev)
      //console.log("isScrollingUp", currentScrollY - prev < -1)
      
      const isPastThreshold = currentScrollY > 200;

      if (window.location.pathname === "/contact" && mobile && window.scrollY < 50) {
        setIsVisible(false)
      } else if (window.location.pathname === "/contact" && !mobile && height > 1200) {
        setIsVisible(true)
      } else if (isScrollingDown && (scrollY + windowHeight + 30 >= documentHeight)) {
        setIsVisible(true)
      } else if (mobile) {
        //console.log("isScrollingUp",isScrollingUp)
        if (isScrollingUp) {
          setIsVisible(true)
        } else if (isScrollingDown) {
          setIsVisible(false)
        }
        
      } else {
        if (isScrollingDown && isPastThreshold) {
          setIsVisible(false)
        } else if (!isScrollingDown) {
          setIsVisible(true)
        }
      }
      
      setPrevScrollY(currentScrollY)
      //prevScrollY.current = currentScrollY
    }, 1000);

    window.addEventListener('scroll', handleScroll);

    /*if (window.location.pathname === "/contact" && mobile && window.scrollY < 50) {
      setIsVisible(false)
    }*/


    const macMenu = throttle((e) => {
      const mouseY = e.clientY;
      const viewportHeight = window.innerHeight;
  
      // Calculate the threshold for the last 10% of the screen
      const threshold = 0.9 * viewportHeight;

      //console.log("threshold",threshold)
      //console.log("mouseY",mouseY)
  
      // Check if the mouse is in the last 10% of the screen
      if (mouseY > threshold) {
        handleHover()
      } else {
        handleLeave()
      }
    }, 100);

    window.addEventListener('mousemove', macMenu)

    return () => {
      window.removeEventListener('wheel', handleScroll);
      window.removeEventListener('mousemove', macMenu);
    };
  }, []);

  useEffect(() => {
    prevScrollYRef.current = prevScrollY
  })

  useEffect(() => {
    if (closeAnim) {
      setTimeout(() => {
        toggleCloseAnim(false)
        toggleMenu(false)
      }, 500) 
    }
  },[closeAnim])

  useEffect(() => {
    if (!mobile) {
      if (menuOpen) {
        document.querySelector("body").classList.add("no-scroll")
      } else {
        document.querySelector("body").classList.remove("no-scroll")
      }
    }

    if (menuOpen && !menuOpenThreeHundredMS) {
      setTimeout(() => toggleMenuThreeHundredMS(true), 300)
    } else {
      toggleMenuThreeHundredMS(false)
    }
  }, [menuOpen])


  const handleLogoClick = (e) => {
    if (isAtHome) {
      e.preventDefault()
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })

    } else {
      toggleCloseAnim(true)
    }

    //toggleCloseAnim(true)
  }


  const handleHover = () => {
    if (window.innerWidth <= 1000) return
      else return setIsHovered(true);
  };
  const handleLeave = () => {
    if (window.innerWidth <= 1000) return
      else return setIsHovered(false);
  };


  return (<>
    {menuOpen && !mobile && pannel(closeAnim,toggleCloseAnim,t)}

    <motion.div
      className={`navTogglerFrame ${mobile && menuOpen ? "mobileOpen" : ""}`}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      animate={mobile
        ? {
          y: menuOpen ? 0 : isVisible ? "calc(100% - 5.5rem)" : "100%"
        } : {}
      }
      transition={mobile
        ? {
          duration: .5,
          type: "spring",
          stiffness: 40
        } : {}
      }
    >

      <motion.div
        initial={{ y: 0 }}
        animate={mobile
          ? {} : {
            y: isHovered || isVisible ? 0 : 100
        }}
        transition={mobile
          ? {} :{
            duration: 0.5
        }}
        className="navToggler"
      >

        {!mobile ? <motion.div
          initial={{ scale: 1 }}
          animate={{
            scaleX: menuOpen ? 5 : 1,
            y: menuOpen ? "-30vh" : 0,
            scaleY: menuOpen ? 10 : 1,
            opacity: menuOpen ? 0 : 1,
            display: menuOpenThreeHundredMS ? "none" : ""
          }}
          transition={{ duration: menuOpen ? .5 : .3 }}
          className={`navTogglerBg ${menuOpen ? "bgAnim" : ""}`}
        /> : <div/>}

        <motion.div
          animate={mobile ? {} : {
            opacity: menuOpen ? 0 : 1,
            x: menuOpen ? -300: 0,
            y: menuOpen ? "-50vh": 0,
            scale: menuOpen ? 2 : 1,
            display: menuOpenThreeHundredMS ? "none" : ""
          }}
          transition={mobile ? {} : { duration: .8, type: "spring" }}
        >
          {isAtHome
            ?
            <a onClick={handleLogoClick}>
              <img src={logoSrc} alt="logo"/>
            </a>   
            :
            <Link to="/" onClick={handleLogoClick}>
              <img src={logoSrc} alt="logo"/>
            </Link>          
          }
        </motion.div>

        <div className="clickable" onClick={() => toggleMenu(v => !v)}>
          <MenuIcon toggle={menuOpen} />
        </div>

        <motion.div
          animate={mobile ? {} : {
            scale: menuOpen ? 2 : 1,
            opacity: menuOpen ? 0 : 1,
            x: menuOpen ? -300: 0,
            y: menuOpen ? -150: 0,
            display: menuOpenThreeHundredMS ? "none" : ""
          }}
          transition={mobile ? {} : { duration: .8, type: "spring" }}
        >
           <Link to="contact" onClick={() => toggleCloseAnim(true)}>{t("touchBar.letsStart")}</Link>
        </motion.div>
      </motion.div>


    {mobile && (
        <nav>
        <Link to="process" onClick={() => toggleCloseAnim(true)}>{t("menu.howWeWork")}</Link>
        <Link to="clients" onClick={() => toggleCloseAnim(true)}>{t("menu.ourClients")}</Link>
        <Link to="contact" onClick={() => toggleCloseAnim(true)}>Contact</Link>
      </nav>
    )}
    </motion.div>
  </>)
}
