import { useState, useEffect, useRef, useLayoutEffect } from "react";

import { motion } from "framer-motion";
import { throttle } from "lodash";
import useMouse from "@react-hook/mouse-position";

import image from "../assets/scrollDown.png";

//import './CustomCursor.css';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function scrollDown(props) {
  const [rotation, setRotation] = useState(0);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [lastScrollDirection, setLastScrollDirection] = useState("down");
  const [rotateInterval, setRotateInterval] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const [scrollDownWidth, setScrollDownWidth] = useState(50);
  const out = useRef(true)

  const [width, height] = useWindowSize();
  const mobile = width <= 1000

  const mouse = useMouse(props.appTargetRef, {
    enterDelay: 100,
    leaveDelay: 100
  })

  useEffect(() => {
    const handleScroll = throttle(() => {
      const currentScrollY = window.scrollY;
      const isPastThreshold = currentScrollY > 200;
      const scrollDirection = currentScrollY > prevScrollY ? "down" : "up";

      if (scrollDirection !== lastScrollDirection) {
        // If the scroll direction changes, update the last scroll direction
        setLastScrollDirection(scrollDirection);
      }

      // Adjust the rotation angle based on the scroll direction
      if (scrollDirection === "down") {
        setRotation(rotation + 2); // Increase the rotation angle
      } else {
        setRotation(rotation - 2); // Decrease the rotation angle
      }

      setIsVisible(!isPastThreshold);
      setPrevScrollY(currentScrollY);
    }, 500);

    const rotateIntervalId = setInterval(() => {
      if (lastScrollDirection === "down") {
        setRotation(rotation + 0.1); // Rotate right
      } else {
        setRotation(rotation - 0.1); // Rotate left
      }
    }, 5)
    
    if (props.hideOnMobile && mobile) {
      // return
    } else {
      setScrollDownWidth(document.getElementById("mi").offsetWidth)
  
      window.addEventListener("scroll", handleScroll);
  
      setRotateInterval(rotateIntervalId);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearInterval(rotateIntervalId);
    };
  }, [rotation, prevScrollY]);


  let mouseXPosition = 0;
  let mouseYPosition = 0;


  // IF OUT SCREEN
  if (!mouse.x && !mouse.y) {
    out.current = true
    document.getElementById("follower")?.classList.remove("forceHidden")
  } else {
    document.getElementById("follower")?.classList.add("forceHidden")
    out.current = false
    mouseXPosition = mouse.clientX;
    mouseYPosition = mouse.clientY;
  }

  const style = {
    transform: `rotate(${rotation}deg)`,
  };

  let y = out.current || mobile ? window.innerHeight/1.5 : mouseYPosition - (scrollDownWidth/2)
  let x = out.current || mobile ? window.innerWidth-scrollDownWidth/1.5 : mouseXPosition - (scrollDownWidth/2)
  
  if (props.hideOnMobile && mobile) return <div/>

  return (
    <motion.div
      animate={{
        y, x,
        opacity: isVisible ? 1 : 0
      }}
      transition={{ duration: 0.5 }}
      className="scrollDown custom-cursor"
      id="mi"
    >
      <img src={image} style={style} />
    </motion.div>
  );
}
