import { useState, useRef, useEffect, useLayoutEffect } from "react";

import { motion, useMotionValue } from "framer-motion";
import clamp from "lodash/clamp";
import range from "lodash/range";

/*const Dots = ({ count, active }) => (
  <div className="dot-container">
    {range(count).map(i => (
      <motion.div
        className="dot"
        initial={false}
        animate={{
          scale: active === i ? 1.5 : 1,
          opacity: active === i ? 1 : 0.5,
        }}
      />
    ))}
  </div>
)*/

/*const Slide = ({ color, ...rest }) => (
  <div
    style={{ backgroundColor: color }}
    className="slide"
    {...rest}
  />
)*/

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function Slider(props) {
  const [width, setWidth] = useState(350);
  const [active, setActive] = useState(0);

  const [windowWidth, height] = useWindowSize();

  const constraintsRef = useRef(null);

  const x = useMotionValue(0);
  const drag = useMotionValue(0);

  useEffect(() => {
    setActive(props.active);
  }, [props.active]);

  useEffect(() => {
    if (props.forceActive >=0 && active !== props.forceActive) setActive(props.forceActive);
  }, [props.forceActive]);

  useEffect(() => {
    if (constraintsRef.current?.firstChild?.firstChild) {
      //console.log(constraintsRef.current.firstChild.firstChild);
      setWidth(constraintsRef.current.firstChild.firstChild.offsetWidth);
    }
  }, [constraintsRef.current, windowWidth]);

  const dragEndHandler = (event, info) => {
    const offset = info.offset.x;
    
    if (Math.abs(offset) > 20) {
      const direction = offset < 0 ? 1 : -1;

      setActive((active) => {
        let newActive = clamp(active + direction, 0, props.children.length - 1);

        console.log("new active here -> ", newActive)
        console.log(props.children.length)
       
        if (props.cbChanged) props.cbChanged(newActive);
          else setActive(newActive)
        
        return newActive;
      });
    }
  };

  return (
    <>
      <div className="sliderContainer" ref={constraintsRef}>
        <motion.div
          onDragEnd={dragEndHandler}
          dragConstraints={{
            left: 0,
            right: 0,
        }}//{constraintsRef}
          drag="x"
          className={`slider ${props.className ? props.className : ""}`}
          animate={{
            x: props.cardMode
              ? 0
              : -1 * active * width,
          }}
          transition={{ duration: 0.5 }}
          key={JSON.stringify(windowWidth)}
        >
          {props.children}
        </motion.div>

        {/*<Dots count={props.children.length} active={active} />*/}
      </div>
    </>
  );
}
