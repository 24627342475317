import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { AnimatePresence } from "framer-motion";
import {
  useLocation,
  createBrowserRouter,
  RouterProvider,
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Frame from "./Frame";
import Splash from "./components/Splash";
import Plausible from 'plausible-tracker'

import NotFound from "./pages/404";
import WeWork from "./pages/wework";
import Clients from "./pages/clients";
import Landing from "./pages/landing";
import Contact from "./pages/contact";
import Legal from "./pages/legal";
import Admin from "./pages/admin"

import './fonts/Aeonik-Light.otf';
import './fonts/Aeonik-Regular.otf';
import './fonts/Aeonik-Medium.otf';
import './fonts/Aeonik-Bold.otf';
import './fonts/Aeonik-Black.otf';

import "./styles.scss";

import './i18n';

const error = { errorElement: <NotFound /> };
const router = createBrowserRouter([
  {
    path: "/",
    element: <Frame />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      {
        path: "/legal",
        element: <Legal />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/process",
        element: <WeWork />,
      },
      {
        path: "/clients",
        element: <Clients />,
      },
      {/*
        path: "/maison",
        element: <Admin/>
      */}
    ],
  },
]);

/*const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Frame />} errorElement={<NotFound />}>
          <Route path="" element={<Landing />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};*/


const { enableAutoPageviews } = Plausible({
  domain: 'dstudio.company'
})

enableAutoPageviews()

ReactDOM.createRoot(document.getElementById("root")).render(
  <StrictMode>
    <Splash />

    <AnimatePresence initial={true}>
      <RouterProvider router={router} />
    </AnimatePresence>
  </StrictMode>,
);