import { useTranslation } from "react-i18next";

import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError()
  const { t, i18n } = useTranslation()

  return (
    <div id="errorPage">
      <h1>404</h1>
      <p>{t("404.title")}</p>

      <a href="/">{t("404.cta")}</a>
    </div>
  )
}