import { motion } from "framer-motion"

export default function close(props) {
    const t = props.toggle

    return (
    <motion.svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" /*viewBox="0 0 20 14"*/>
        <motion.rect initial={{ translateY: 0 }} animate={{ rotate: t ? -45 : 0, translateY: t ? 6 : 0 }} id="Rectangle_5" data-name="Rectangle 5" width="20" height="2" rx="1" transform="translate(0 0)" fill={"#fff"/*t ? "#fff" : "blue"*/}/>
        <motion.rect initial={{ translateY: 6, translateX: 4 }} animate={{ opacity: t ? 0 : 1 }} id="Rectangle_6" data-name="Rectangle 6" width="13" height="2" rx="1" transform="translate(0 0)" fill="#fff"/>
        <motion.rect initial={{ translateY: 12 }} animate={{ rotate: t ? 45 : 0, translateY: t ? 6 : 12 }} id="Rectangle_7" data-name="Rectangle 7" width="20" height="2" rx="1" transform="translate(0 0)" fill={"#fff"}/>
    </motion.svg>
)}
  