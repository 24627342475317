export default function close2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.129" height="11.129" viewBox="0 0 11.129 11.129">
      <g id="Groupe_315" transform="translate(5.564 -1.322) rotate(45)">
        <line id="Ligne_1" y2="9.739" transform="translate(4.869 0)" fill="none" stroke="#e8e8ed" strokeLinecap="round" strokeWidth="3"/>
        <line id="Ligne_2" y2="9.739" transform="translate(9.739 4.869) rotate(90)" fill="none" stroke="#e8e8ed" strokeLinecap="round" strokeWidth="3"/>
      </g>
    </svg>

)}
