import { useState, useEffect, useRef } from "react"
import { motion } from "framer-motion";

import { throttle } from 'lodash';
import useMouse from "@react-hook/mouse-position";

//import './CustomCursor.css';

export default function CustomCursor(props) {
  const [clickable, setClickable] = useState(false)

  const out = useRef(true)
  const clickableRef = useRef(false)

  useEffect(() => {
    const onHover = throttle((e) => {
      try {
        if (
          e.target?.className?.includes("clickable")
          || e.target?.parentNode?.className?.includes("clickable")
          || e.target?.parentNode?.parentNode?.className?.includes("clickable")
          || e.target?.parentNode?.parentNode?.parentNode?.className?.includes("clickable")
          || e.target?.nodeName === "A" || e.target?.parentNode?.nodeName === "A" || e.target?.parentNode?.parentNode?.nodeName === "A" || e.target?.parentNode?.parentNode?.parentNode?.nodeName === "A"
        ) {
          setClickable(true)
        } else if (clickableRef.current) {
          setClickable(false)
        }
      } catch(e) {
        return
      }

    }, 500)

    window.addEventListener('mouseover', onHover)

    return () => {
      window.removeEventListener('hover', onHover)
    }
  }, [])

  useEffect(() => {
    clickableRef.current = clickable
  }, [clickable])


  const mouse = useMouse(props.appTargetRef, {
    enterDelay: 100,
    leaveDelay: 100
  })

  //console.log("mouse", mouse)

  let mouseXPosition = 0;
  let mouseYPosition = 0;

  if (!mouse.x && !mouse.y) {
    out.current = true
  } else {
    out.current = false
    mouseXPosition = mouse.clientX;
    mouseYPosition = mouse.clientY;
  }

  return (
    <div className="custom-cursor">
      <motion.div
        className={`cursor ${clickable ? "empty" : ""}`}
        animate={{
          opacity: out.current ? 0 : 1,
          x: mouseXPosition,
          y: mouseYPosition
        }}
        transition={{ type: "ease", duration: .1 }}
      />
      <motion.div
        className={`follower ${clickable ? "full" : ""}`}
        id="follower"
        animate={{
          opacity: out.current ? 0 : 1,
          x: mouseXPosition - 12,
          y: mouseYPosition + 12,
          scale: clickable ? 1.3 : 1
        }}
        transition={{ type: "tween", duration: out.current ? 0 : 1.3, ease: "circOut" }}
      />
    </div>
  );
};