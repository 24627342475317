import { useState } from "react";

import { useNavigate } from "react-router-dom"

import { motion, AnimatePresence } from "framer-motion";

import IconPlus from "../assets/plus";
import IconMenuBg from "../assets/menuBg";


const Menu = (props) => {
  const menuServices = props.active
  const navigate = useNavigate()

  const tabs = [
      "Apps", "Website", "Branding"
  ]

  const plusClick = () => {
    if (props.mobile) {
      window.scrollTo(0,0)
      setTimeout(() => navigate("/process"), 50)
    } else {
      props.setActive(menuServices === 3 ? 0 : 3)
    }
  }

  return (
    <div className="togglerFrame" /*ref={togglerFrameRef}*/>
        <motion.div
          //initial={{ opacity: 0 }}
        >
          <IconMenuBg />
        </motion.div>
        

        {/*toggleServicesBgOffset !== -1 && (
          <motion.div
            initial={{ opacity: 0 }}
            className="togglerBgPill"
            animate={{ x: toggleServicesBgOffset }}
            transition={{ duration: 1, type: "ease" }}
          />
        )*/}

        {tabs.map((item,i) => (
            <motion.div
              key={item.label}
              className={`clickable toggler ${i === menuServices ? "toggler-active" : ""}`}
              onClick={() => props.setActive(i)}
            >
                {item}
                {i === menuServices ? (
                    <motion.div className="underline" layoutId="underline" />
                ) : null}
            </motion.div>
        ))}

        {/*<motion.div
          initial={{ opacity: 0 }}
          onClick={() => changeMenuServices(0)}
          id="toggler-apps"
          className={`toggler ${menuServices === 0 ? "toggler-active" : ""}`}
        >
          Apps
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          onClick={() => changeMenuServices(1)}
          id="toggler-website"
          className={`toggler ${menuServices === 1 ? "toggler-active" : ""}`}
        >
          Website
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          onClick={() => changeMenuServices(2)}
          id="toggler-branding"
          className={`toggler ${menuServices === 2 ? "toggler-active" : ""}`}
        >
          Branding
        </motion.div>*/}

        <motion.div
          //initial={{ x: -300 }}
          //whileInView={{ x: 0 }}
          //transition={{ duration: 1, delay: 1.3 }}
          //viewport={{ once: false }}
          onClick={plusClick}
          id="toggler-terms"
          className={`toggler ${menuServices === 3 ? "toggler-active" : ""}`}
        >
          <motion.div
            //initial={{ x: -300, opacity: 0, scale: 0 }}
            //whileInView={{ x: 0, opacity: 1, scale: 1 }}
            //transition={{ duration: 1, delay: .3 }}
            //viewport={{ once: false }}
          >
            <IconPlus />
          </motion.div>
        </motion.div>

    </div>
    );
};

export default Menu;
