import { useEffect, useState, useLayoutEffect } from "react"

import "../styles/splash.scss"

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function Splash() {
    const [show,toggleShow] = useState(true)

    const [width, height] = useWindowSize()
    const mobile = width <= 678

    useEffect(() => {
        document.querySelector("body").classList.add("no-scroll")
        setTimeout(() => {
            toggleShow(false)
            document.querySelector("body").classList.remove("no-scroll")
        }, 3500)
    }, [])
    
    if (!show) return null

    return (
        <div className="splash">
            <video autoPlay muted preload="auto" playsInline loop={true}>
                <source src={mobile ? "/mobileLoad.mp4" : "/load.mp4"} type="video/mp4" />
            </video>
        </div>
    )
}