import { useState } from "react"

import { useTranslation, Trans } from "react-i18next";
import { motion } from "framer-motion"

import AnimatedAppear from "../components/AnimatedAppear"

import ChevronIcon from "../assets/chevron";

import "../styles/wework.scss"

export default function WeWork() {
  const { t, i18n } = useTranslation()
  
  const services = [
    t("services.design").split(","),
    t("services.development").split(","),
    t("services.communication").split(","),
    t("services.strategy").split(",")
  ]

  return (
    <AnimatedAppear>
    <div className="weWork">

        <div className="head">
          <h1><Trans i18nKey="process.title" components={{ b: <b/> }}/></h1>
        </div>

        <div className="line">
          <div className="bubble title"><Trans i18nKey="process.howWeDoIt"/></div>
          <div className="bubble">
            <Trans i18nKey="process.integrateDesignContent"/>
          </div>
        </div>

        <div className="process">
          <div className="bubble title"><Trans i18nKey="process.howWeWork"/></div>

          <div className="row">
            <div className="card">
              <span>01</span>
              <div><b><Trans i18nKey="process.contactUs" components={{ b: <b/> }}/></b></div>
              <p><Trans i18nKey="process.letsGrabACoffee"/></p>
            </div>

            <div className="card">
              <span>02</span>
              <div><b><Trans i18nKey="process.firstMeeting" components={{ b: <b/> }}/></b></div>
              <p><Trans i18nKey="process.exploreBrandRequirements"/></p>
            </div>

            <div className="card">
              <span>03</span>
              <div><b><Trans i18nKey="process.organizing" components={{ b: <b/> }}/></b></div>
              <p><Trans i18nKey="process.receiveProposal"/></p>
            </div>

            <div className="card">
              <span>04</span>
              <div><b><b>Let's start</b></b></div>
              <p><Trans i18nKey="process.start"/></p>
            </div>
          </div>
        </div>

        <div className="services">
          <div className="bubble title"><Trans i18nKey="process.services"/></div>

          <div className="row">
            <ServiceCard img="/potionDesign.jpg" title={t("services.titleDesign")} list={services[0]}/>

            <ServiceCard img="/potionDev.jpg" title={t("services.titleDev")}  list={services[1]}/>

            <ServiceCard img="/potionCom.jpg" title={t("services.titleCom")}  list={services[2]}/>

            <ServiceCard img="/potionStrat.jpg" title={t("services.titleStrat")}  list={services[3]}/>
          </div>
          
        </div>
    </div>
    </AnimatedAppear>
  )
}


function ServiceCard(props) {
  const [toggled,setToggled] = useState(false)

  return (
    <div
      className={`elem clickable ${toggled ? "show" : ""}`}
      onClick={() => setToggled(v => !v)}
    >
      <img
        alt="illu"
        src={props.img}
      />
      <div>
        <div className="title">
          <h3>{props.title}</h3>
          <ChevronIcon/>
        </div>
        <div className="list">
          {props.list.map((item,i) => (
            <span key={i}>{item}</span>
          ))}
        </div>
      </div>
  </div>
  )
}